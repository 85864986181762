// @flow
export const PAGE_TITLE = 'Result Entry Overview | myQAP Portal'

export const PAGE_HEADING = 'Result Entry Overview'

export const TABLE_NO_DATA_NOTICE = 'No submissions found'

export const TAB_OPTIONS = {
  missed: {
    key: 'missed',
    value: 'missed',
    label: 'Missed Submission',
    state: ['missed'],
    filters: {
      roundStatus: 'closed',
      lateSubmission: false,
      status: 'missed'
    },
    overviewTableExportSettings: {
      fileNamePrefix: 'result_entry_missed',
      tabName: 'result_entry_missed',
      titles: [
        {label: 'Showing number of surveys:'},
        {label: 'Closed between:', key: 'dateRange'},
        {label: 'Status:', key: 'status'},
      ],
      columns: [
        { key: 'participantGroupName', columnName: 'Participant group', commonData: true },
        { key: 'disciplineName', columnName: 'Discipline', commonData: true },
        { key: 'programName', columnName: 'Program Name' },
        { key: 'surveyCount', columnName: 'Total Surveys' },
      ],
    },
  },
  late: {
    key: 'late',
    value: 'late',
    label: 'Late Submission',
    state: ['late'],
    filters: {
      roundStatus: 'closed',
      lateSubmission: true,
      status: 'late'
    },
    rewriteFilters: {
      status: 'submitted'
    },
    overviewTableExportSettings: {
      fileNamePrefix: 'result_entry_late',
      tabName: 'result_entry_late',
      titles: [
        {label: 'Showing number of surveys:'},
        {label: 'Closed between:', key: 'dateRange'},
        {label: 'Status:', key: 'status'},
      ],
      columns: [
        { key: 'participantGroupName', columnName: 'Participant group', commonData: true },
        { key: 'disciplineName', columnName: 'Discipline', commonData: true },
        { key: 'programName', columnName: 'Program Name' },
        { key: 'surveyCount', columnName: 'Total Surveys' },
      ],
    },
  }
}

export const ROUND_OVERVIEW_TABLE_COLUMNS = [
  { displayName: 'Participant group' },
  { displayName: 'Discipline' },
  { displayName: 'Programs' },
  { displayName: 'Surveys' }
]
