// @flow
import {
  DASHBOARD_REPORT_TYPE,
  REPORTS_IN_PROGRESS_TAB_FRESHNESS_DAYS,
  REPORTS_NOT_REVIEWED_TAB_FRESHNESS_DAYS,
  REPORTS_OVERVIEW_TABLE_HINT_NOT_REVIEWED
} from 'qap/constants'

export const PAGE_TITLE = 'Reports Overview | myQAP Portal'

export const PAGE_HEADING = 'Report Review Overview'

export const TABLE_NO_DATA_NOTICE = 'No reports found'

export const TAB_OPTIONS = {
  inProgress: {
    key: 'in_progress',
    value: 'in_progress',
    label: 'In Progress',
    state: ['in_progress'],
    overviewTableColumns: [
      { displayName: 'Participant group' },
      { displayName: 'Discipline' },
      { displayName: 'Programs' },
      { displayName: 'Reports' },
      { displayName: '< 30 days' },
      { displayName: '> 30 days' }
    ],
    filters: {
      reviewStatus: 'in_progress',
      reportType: DASHBOARD_REPORT_TYPE
    },
    searchParams: {
      freshnessAge: REPORTS_IN_PROGRESS_TAB_FRESHNESS_DAYS
    },
    overviewTableExportSettings: {
      fileNamePrefix: 'reports_in_progress',
      tabName: 'reports_in_progress',
      titles: [
        {label: 'Showing number of reports:'},
        {label: 'Published between:', key: 'dateRange'},
        {label: 'Review status:', key: 'status'},
      ],
      columns: [
        { key: 'participantGroupName', columnName: 'Participant group', commonData: true },
        { key: 'disciplineName', columnName: 'Discipline', commonData: true },
        { key: 'programName', columnName: 'Program Name' },
        { key: 'reportCount', columnName: 'Total Reports' },
        { key: 'freshCount', columnName: '< 30 days' },
        { key: 'staleCount', columnName: '> 30 days' },
      ],
    },

  },
  notReviewed: {
    key: 'not_started',
    value: 'not_started',
    label: 'Not Reviewed',
    state: ['not_started'],
    overviewTableColumns: [
      { displayName: 'Participant group' },
      { displayName: 'Discipline' },
      { displayName: 'Programs' },
      { displayName: 'Reports' }
    ],
    filters: {
      reviewStatus: 'not_started',
      reportType: DASHBOARD_REPORT_TYPE
    },
    searchParams: {
      freshnessAge: REPORTS_NOT_REVIEWED_TAB_FRESHNESS_DAYS
    },
    overviewTableExportSettings: {
      fileNamePrefix: 'reports_not_reviewed',
      tabName: 'reports_not_started',
      titles: [
        {label: 'Showing number of reports:'},
        {label: 'Published between:', key: 'dateRange'},
        {label: 'Review status:', key: 'status'},
      ],
      columns: [
        { key: 'participantGroupName', columnName: 'Participant group', commonData: true },
        { key: 'disciplineName', columnName: 'Discipline', commonData: true },
        { key: 'programName', columnName: 'Program Name' },
        { key: 'staleCount', columnName: 'Total Reports' },
      ],
    },
    buildCountLinkByFreshStatus: true,
    tableHint: REPORTS_OVERVIEW_TABLE_HINT_NOT_REVIEWED
  }
}
